import { startCase } from "lodash";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

export default {
    name: "neo-input",
    components: {
        "font-awesome-icon": FontAwesomeIcon,
    },
    props: {
        type: {
            type: String,
            default: "text",
        },
        required: { type: Boolean, default: false },
        placeholder: {
            default: "",
            type: String
        },
        prependIcon: "",
        label: {
            type: String,
            default: ""
        },
        value: {},
        icon: {},
        borderFull: {},
        borderColor: {},
        changeCase: {},
        shadow: {
            default: true,
        },
        textLimit: {
            type: Number,
            default: -1,
        },
        disabled: Boolean,
        readOnly: {
            type: Boolean,
            default: false
        },
        error: {
            type: Boolean,
            default: false
        },
        mappedKey:{
            default: null
        },
        inputId: {
            type: String,
            default: ""
        },
        showAppendIcon:{
            type: Boolean,
            default: false,
        },
        appendIcon:{
            type: String,
        },
        appendHandleEvent:{
            type: Function
        }
    },
    data() {
        return {
            ico: "",
            focus: false,
            startCase: startCase,
        };
    },
    computed: {},
    created() {
        this.ico = this.icon;
    },
    methods: {
        onInput(newValue, mappedKey, placeholder) {
            if (this.textLimit !== -1) {
                let val = newValue.slice(0, this.textLimit - 1);
                this.$emit("input", val, mappedKey, placeholder);
            } else {
                this.$emit("input", newValue, mappedKey, placeholder);
            }
        },
        // handleAppendIconClick(){
        //     this.$emit("appendHandleEvent")
        // },
    },
};
