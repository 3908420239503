<template>
    <div class="text-sm breadcrumbs">
        <ul>
            <li v-for="(breadcrumb, idx) in breadcrumbList" :key="idx"  class="flex items-center text-primary capitalize gap-2 text-gray-600 font-bold" >
                <!-- <font-awesome-icon v-if="crumb.icon" :icon="crumb.icon" /> -->
                {{ breadcrumb.name }}
            </li>
            <li v-for="(breadcrumb, id) in customCrumbs" :key="id"  class="flex items-center gap-2 text-primary capitalize text-gray-600 font-bold" >
                <!-- <font-awesome-icon v-if="crumb.icon" :icon="crumb.icon" /> -->
                {{ breadcrumb.name }}
            </li>
            
        </ul>
    </div>
</template>

<script>
export default {
  name: 'Breadcrumb',
  data () {
    return {
      breadcrumbList: [],
      // customCrumbs: null,
    }
  },
  props: {
    showOnlyCustomCrumbs: {
      type: Boolean,
      default: false,
    },
    customCrumbLists: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    customCrumbs() {
      return this.customCrumbLists;
    },
  },
  mounted () { 
    this.updateList(); 
    // this.updateDynamicRoute() 
  },
  watch: { '$route' () { this.updateList(); this.updateDynamicRoute() } },
  methods: {
    routeTo (pRouteTo) {
      if (this.breadcrumbList[pRouteTo].link) this.$router.push(this.breadcrumbList[pRouteTo].link)
    },
    updateList () { this.breadcrumbList = this.$route.meta.breadcrumb },
    // updateDynamicRoute(){ this.customCrumbs = this.$route.meta.customCrumbs },
  }
}
</script>

<style>
.breadcrumbs>ul>li+:before{
  margin-left: 1rem;
}
</style>
