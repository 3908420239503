<template>
  <div
    class="w-full pr-6 ml-0 py-2 flex items-center justify-between min-w-full"
  >
  <div class="flex gap-4 items-center">
      <div>
        <Button
          @click="handleBack"
          v-if="backButton"
          icon="angle-left"
          text="Back"
          type="primary"
          outline
        >
        </Button>

        <Input
          v-if="showSearchBar"
          :changeCase="true"
          :shadow="false"
          placeholder="Search"
          bg="#fff"
          margin="0"
          class="h-9"
          v-model="searchValue"
          @input="handleSearchFilter"
        />
      </div>
      <BreadCrumbs  :customCrumbLists="customCrumbLists"/>

        <template>
            <slot name="header-filters">
                
            </slot>
        
        </template>
  </div>
    
    <div>
      <!-- class="inline-block px-6 py-2.5 pl-5 pr-5 text-white-text font-medium text-xs leading-tight uppercase rounded-xl shadow-md hover:bg-blue-700 hover:shadow-lg capitalize focus:shadow-lg focus:outline-none focus:ring-0  active:shadow-lg transition duration-150 ease-in-out"
      
      style="background:#0D69D5" -->
      <template>
            <slot name="actions">
                <Button type="primary" v-if="showBtn" @click="handleClick" :text="buttonText" >
                    <!-- <font-awesome-icon class="text-base mr-4" :icon="buttonIcon ? buttonIcon : 'plus'" /> -->
                </Button> 
            </slot>
        
        </template>

      
    </div>
  </div>
</template>
<script>
import Input  from "@/components/input";
import Button from "@/components/button"
import BreadCrumbs from "@/components/bread-crumbs/bread-crumbs"
export default {
  name: "sub-header",
  props: [
    "backButton",
    "showInputTypeModal",
    "handleClick",
    "buttonText",
    "backRedirectionUrl",
    "showBtn",
    "handleSearchFilter",
    "showSearchBar",
    "buttonIcon",
    "showOnlyCustomCrumbs",
    "customCrumbLists"
  ],

  data() {
    return {
      searchValue: "",
    };
  },
  methods: {
    handleBack() {
      if (this.backRedirectionUrl) {
        this.$router.push({ path: this.backRedirectionUrl });
      } else {
        this.$router.back();
      }
    },
    handleChange() {
      this.handleSearchFilter(this.searchValue);
    },
    selected(crumb) {
      console.log(crumb);
    },

  },
  computed: {
    filteredList() {
      return this.users.filter((post) => {
        return post.user_name
          .toLowerCase()
          .includes(this.searchValue.toLowerCase());
      });
    },
  },
  components: {
    Input ,Button,BreadCrumbs
  },
};
</script>
<style scoped>
.field__Card svg {
  color: rgb(82, 82, 82);
}
</style>
