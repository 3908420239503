export const columns = [
    {"label":"Name","field":"field_info"},
    {"label":"Status","field":"field_type_status"},
    {"label":"Packages","field":"packaged_included"},
    {"label":"SLA","field":"sla"},
    {"label":"Automatic/Manual","field":"is_automatic"},
    
    ]
 
    
    

export const rows = [
        {
            id: 1,
            info: {
                name:"Employement Check",
                description: "Lorem ipsum dolor sit amet consectetur adipisicing elit",
            },
            checkSections: [
                {
                    sectionName: "section 1",
                    checkFields: [
                        {
                            "id": 1,
                            "name": "Subject Name",
                            "label": "Name",
                            "description": "string",
                            "placeholder": "string",
                            "created_at": "2022-10-07T08:32:19.379Z",
                            "updated_at": "2022-10-07T08:32:19.379Z",
                            "is_custom": true,
                            "tenant_id": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
                            "is_cascaded": false,
                            "checks": [],
                            "checks_count": 10,
                            "type": "input-text"
                        },
                        {
                            "id": 2,
                            "name": "Age",
                            "label": "Age",
                            "description": "please provide password",
                            "placeholder": "Password",
                            "created_at": "2022-10-07T08:32:19.379Z",
                            "updated_at": "2022-10-07T08:32:19.379Z",
                            "is_custom": true,
                            "tenant_id": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
                            "is_cascaded": false,
                            "checks": [],
                            "checks_count": 10,
                            "type": "input-text"
                        },
                        {
                            "id": 3,
                            "name": "Gender",
                            "label": "Gender",
                            "description": "checkbox",
                            "placeholder": "Checkbox",
                            "created_at": "2022-10-07T08:32:19.379Z",
                            "updated_at": "2022-10-07T08:32:19.379Z",
                            "is_custom": true,
                            "tenant_id": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
                            "is_cascaded": true,
                            "checks": [],
                            "checks_count": 10,
                            "type": "radio"
                        },
                    ]
                },
                {
                    sectionName: "section 2",
                    checkFields: []
                },
            ],
            checkParams:[
                {
                    key: "Employment History",
                    value: "4 years",
                },
                {
                    key: "Minimum Designation",
                    value: "2"
                }
            ],
            type_status: 'system provided',
            packaged_included: 8,
            sla: 9,
            is_automatic: true

        },
        {
            id: 2,
            info: {
                name:"Education",
                description: "Lorem ipsum dolor sit amet consectetur adipisicing elit",
            },
            checkSections: [],
            type_status: 'custom',
            packaged_included: 8,
            sla: 12,
            is_automatic: false

        },
        {
            id: 3,
            info: {
                name:"DBS Basic",
                description: "Lorem ipsum dolor sit amet consectetur adipisicing elit",
            },
            checkSections: [],
            type_status: 'custom',
            packaged_included: 8,
            sla: 14,
            is_automatic: true

        },
        {
            id: 4,
            info: {
                name:"Gap Validation",
                description: "Lorem ipsum dolor sit amet consectetur adipisicing elit",
            },
            checkSections: [],
            type_status: 'system provided',
            packaged_included: 8,
            sla: 11,
            is_automatic: false

        },

        
    ]



// export const checkData = {
//     id: '4547898989',
//     name: '',
//     description: '',
//     tenant_id: '',
//     sla: '',
//     is_custom: '',
//     created_at: '',
//     last_updated: '',
//     parameters: {},
//     sections: [
//       {
//         id: '',
//         name: '',
//         order: '',
//         check_id: '',
//         fields: [
//           {
//             id: '',
//             tenant_field_id: '',
//             section_id: '',
//             internal_use_only: '',
//             block_id: '',
//             multi: false,
//             order: int,
//             max_field_or_block_count: int,
//             min_field_or_block_count: int,
//             is_top_level: false,
//             label: '',
//             layout_id: '',
//             user_override_label: false,
//             size_default: int,
//             size_sm: int,
//             size_md: int,
//             size_lg: int,
//             size_xs: int,
//             reference_id: '',
//             dependent_fields: [
//               'id',
//               'id'
//             ],
//             roles: [
//               {
//                 id: '',
//                 role_id: '',
//                 role_read: false,
//                 role_write: false,
//                 role_visibility: false,
//                 role_mandatory: false,
//                 title: '',
//                 code: ''
//               }
//             ]
//           }
//         ]
//       }
//     ]
//   }